
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {
  Device as DeviceService,
  Location as LocationService,
} from "@/services/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Device as DeviceModel, Location as LocationModel } from "@/models";
import KiosksFormModal from "./components/KiosksFormModal.vue";
import { translations, notificationAlerts, eventHandler } from "@/mixins";
import moment from "moment";
import Translations from "./components/Translations.vue";
import {mapGetters} from "vuex";
import { Customer } from '@/services/SOLO';
import { Autocomplete, Form, Select, Option } from "element-ui";
import { Connect as ConnectService } from "@/services/SOLO";

@Component({
  components: {
    KiosksItemsTable: () => import("./components/KiosksItemsTable.vue"),
    Events: () => import("./components/Events.vue"),
    LoadingPanel: () => import("@/components/LoadingPanel.vue"),
    KiosksFormModal: () => import("./components/KiosksFormModal.vue"),
    RouteBreadCrumb: () => import("@/components/Breadcrumb/RouteBreadcrumb"),
    [Form.name]: Form,
    [Select.name]: Select,
    [Option.name]: Option,
    [Autocomplete.name]: Autocomplete,
  },
  computed: {
    ...mapGetters({
      getLocale: "app/getLocale",
      getSubscription: 'account/getSubscription',
      getConceptSettings: 'account/getConceptSettings',
      getUserClient: 'account/getUserClient',

    }),
  },
  mixins: [translations, notificationAlerts, eventHandler],
})
export default class Kiosks extends Vue {
  confirm1!: Function
  flagPill: boolean = false;
  isKioskErrorModal: boolean = false;
  getUserClient!: any
  getLocale!: any;
  getSubscription!: any;
  getConceptSettings!: any;
  confirm!: Function
  translate!: Function;
  systemErrorNotification!: Function;
  successNotification!: Function;
  itemLimit: Number = 100;
  loading: Boolean = false;
  devices: Array<DeviceModel> = [];
  locations: Array<LocationModel> = [];
  locationsLoaded: Boolean = false;
  activeKiosk: any = 0;
  kioskCount: any;
  availableKiosk: any = 0;
  customers: any = [];
  customerMeta: any = {}
  $notify: any;
  totalPages: any;
  currentPage: any = 1;
  perPage: number = 10;
  totalDevices: number = 0;
  $refs!: {
    kiosksFormModal: KiosksFormModal;
  };
  locPage: any = 1;
  page: number = 1;
  size: number = 10;
  isOpen: boolean = false;
  saving: boolean = false;
  form: any = {
    "first-name": '',
    "last-name": '',
    mobile: '',
  };
  tab: any = 1

   mounted() {
    this.getDevices(true);
    this.getLocations(this.locPage);


    if (this.getSubscription && this.getSubscription.attributes['paid-features']) {
      for( let i in this.getSubscription.attributes['paid-features']) {
        if (this.getSubscription.attributes['paid-features'][i]['feature'] == 'kiosk') {
          this.availableKiosk = this.getSubscription.attributes['paid-features'][i]['quantity'];

        }
      }
    }
  }

  get deleteMsgBoxText(): String {
    return this.translate("Deactivating the device will stop it immediately! Are you sure you want to deactivate this device?");
  }

  deactivate(e: any) {

    const model = {
      available: 1
    }

    this.confirm(this.$bvModal, this.deleteMsgBoxText)
      .then((value: boolean) => {
        if(value) {
           DeviceService.update(e, model)
            .then(response => {
              this.getDevices(true);
              this.flagPill = response?.data?.data?.attributes?.available;
              this.successNotification(
              "NOTIFICATION",
              "Device successfully deactivated!"
            );
            })
        }
      })
  }

  async checkKiosk() {
    if(this.kioskCount == this.availableKiosk) {
      const re = await this.confirm1(this.$bvModal, 'You need a kiosk license to add a kiosk device, go to Account Page please click (OK).')
      if(re) {
        this.$router.push('/account');
      }
    }
  }
  newDummyCustomer() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }

  get dropDownText(): string {
    if (this.tab === 1) {
      return this.translate('Kiosk')
    } else {
      return this.translate('Events')
    }                   
  }

  resetForm() {
    this.form["first-name"] = '';
    this.form["last-name"] = '';
    this.form.mobile = '';
  }
  public get getClientUserLabel() {
    return this.getUserClient?.attributes?.label;
  }

  async onSave() {
    this.saving = true;

    try {
      let { data } = await ConnectService.createCustomer({
        ...this.form,
        "account-type": 'guest',
        'username': this.form.mobile,
      });

      this.$notify({
        title: `CUSTOMER CREATED`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: `Customer data was created successfully!`,
        type: "success"
      } as any);

      this.closeModal();
      this.resetForm();
    } catch (e) {
      console.log(e);
      /* @ts-ignore */
      Object.keys(e.response.data.messages).map((key: any) => {
        this.$notify({
          title: `CUSTOMER ERROR`,
          verticalAlign: "bottom",
          horizontalAlign: "left",
          /* @ts-ignore */
          message: e.response.data.messages[key][0],
          type: "danger"
        } as any);
      })
    } finally {
      this.saving = false;
    }
  }

   async resetAlarmFunc(item: DeviceModel) {
    console.log("check device data", item);
    let payload = {
          'reset-alarm': true,
           '_method': 'PATCH'
        }
     try {
      const response = await DeviceService.resetAlarm(item.id, payload);
      this.getDevices(true);
      this.successNotification(
      "DEVICE DETAILS",
      "Alarm successfully reset!"
    );

    } catch (error) {
      this.loading = false;
      this.systemErrorNotification();
    }
  }

 async loadNewKiosks() {
    const response = await DeviceService.refreshMdm();

    this.getDevices(false);
    this.successNotification(
      "NEW KIOSKS LOADED",
      "New kiosks has been loaded!"
    );
  }

  onSuccessfulFormSubmit() {
    this.getDevices(false);
  }

  private createItem() {
    this.$refs.kiosksFormModal.open = true;
  }

  private async getItemMdm(item: DeviceModel) {
    let response = await DeviceService.getMdmDeviceDetails(item.id);

    this.getDevices(false);
    this.successNotification(
      "DEVICE DETAILS LOADED",
      "Device details has been loaded!"
    );
  }

  private editItem(item: DeviceModel) {
    console.log("Check Item - Edit --> ", item)
    this.flagPill = item.attributes.available;
    this.$refs.kiosksFormModal.model = {
      id: item.id.toString(),
      "location-id": item.attributes["location-id"]?.toString(),
      "customer-id": item.attributes["customer-id"]?.toString(),
      label: item.attributes.label,
      status: item.attributes.status,
      available: item.attributes.available,
      uuid: item.attributes['device-uuid'],
      "ip-address": item.attributes["ip-address"],
      "terminal-id": item.attributes["terminal-id"],
      "is-settings-password-protected": item?.attributes['is-settings-password-protected'],
      "payment-gateway": item.attributes["payment-gateway"],
      "type": item.attributes["type"],
      "port": item.attributes["port"],
      "printer-settings": {
        'printer-manufacturer': item.attributes["printer-settings"]?.["printer-manufacturer"],
        'receipt-mode': item.attributes["printer-settings"]?.["receipt-mode"],
        'ip-address': item.attributes["printer-settings"]?.["ip-address"],
        'printer-name': item.attributes["printer-settings"]?.["printer-name"],
      },
      'payment-settings': {
        'terminal-poiid': item.attributes["payment-settings"]?.["terminal-poiid"],
        'key-version': item.attributes["payment-settings"]?.["key-version"],
        'key-identification': item.attributes["payment-settings"]?.["key-identification"],
        'key-passphrase': item.attributes["payment-settings"]?.["key-passphrase"],
      },
      "is-maintenance-mode": item.attributes["is-maintenance-mode"]
    };
    this.$refs.kiosksFormModal.open = true;
  }

  onModalClose() {
    // do not perform on first load of KiosksFormModal component
    if (this.$refs.kiosksFormModal) {
      // this.$refs.kiosksFormModal.model = { ...kioskDefaultValue };
    }
  }

  getAdditionalDevices(val: any) {
    if(this.currentPage <= this.totalPages) {
      this.getDevices(val);
    }
  }
  async paginate(page: number) {
    this.currentPage = page;
    this.getDevices(false);
  }

  async getDevices(showLoading: boolean = true) {
    if (showLoading) {
      this.loading = false;
    }

    try {
      const response = await DeviceService.all(this.currentPage, this.perPage);
      this.devices = response.data.data;
      this.totalDevices = response.data.meta.pagination.total;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.systemErrorNotification();
    }
    


  }

  async getLocations(page: number) {
    let lang = 'en-us'

    try {
      const response = await LocationService.fetchLocationsActive(lang, page);
      if (response?.data?.data && Array.isArray(response?.data?.data)) {
        this.locations = [...this.locations, ...response?.data?.data];
        this.locations = this.locations.sort((a: any, b: any) => (a.attributes.name > b.attributes.name) ? 1 : -1);
      }
      if (response.data.meta.pagination.current_page < response.data.meta.pagination.total_pages) {
        this.currentPage = response.data.meta.pagination.current_page + 1;
        this.getLocations(this.currentPage);
      }
    } catch (error) {
      this.systemErrorNotification();
    }
  }

}
